import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { ServicePreferencesDialogViewModel } from '../../viewModel/dialogViewModel/dialogViewModel';

export type OnPreferencesModalServiceSelected = (
  selectedService: Service,
) => void;

export const createOnPreferencesModalServiceSelectedAction = ({
  widgetViewModel,
  setProps,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
}): OnPreferencesModalServiceSelected => {
  return (selectedService) => {
    const dialogViewModel =
      widgetViewModel.dialogViewModel as ServicePreferencesDialogViewModel;

    dialogViewModel.data.inView.service = selectedService;

    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });
  };
};
